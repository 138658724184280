/*Responsive*/

@media screen and (min-width: 1920px) {
	.slider__img {
		width: 100%;
	}
}

@media screen and (min-width: 1600px) {
	.contacts-bg {
		width: 46%;
	}
}

@media screen and (max-width: 1199px) {
	.menu li {
		font-size: 12px;
	}

	.sentence-box {
		padding: 100px 40px 50px 40px
	}

	.sentence-box__link {
		margin: 40px auto 0 auto
	}

	.table-title {
		font-size: 22px;
	}
}

@media screen and (max-width: 991px) {
	.contacts, .contacts-consult, .order-info {
		font-size: 16px;
	}

	.offer__text, .recital__title {
		text-align: center;
	}

	.offer__link {
		margin: 35px auto;
	}

	#sentence {
		padding: 50px 0;
	}

	#contacts {
		height: auto;
		background-color: rgba(0, 0, 0, 0.7);
	}

	.contacts-form {
		margin: 70px 0;
	}

	.contacts-bg {
		display: none;
	}

	.map {
		position: static;
	}
	
	.map, .map iframe {
		height: 400px;
	}

	.table-title, .title-list, .time-cost__title, .info-table {
		font-size: 20px;
	}
}

@media screen and (max-width: 767px) {
	h1 {
		font-size: 30px;
	}

	h2, .title-doc {
		font-size: 24px;
	}

	h3 {
		font-size: 22px;
	}

	.offer-list__item {
		font-size: 20px;
	}

	.visa__img {
		width: 100%;
		max-width: 350px;
		margin: 0 auto;
	}

	.arrows-slider {
		top: 40%;
		font-size: 32px;
	}

	.slider-prev {
		left: -10px;
	}

	.slider-next {
		right: -10px;
	}

	.footer-box {
		margin-top: 40px;
	}

	.forma {
		margin-top: 50px;
	}

	#document {
		padding: 80px 0;
	}

	.table-title {
		font-size: 16px;
	}

	.table {
		font-size: 14px;
	}
}

@media screen and (max-width: 575px) {
	.top-header {
		padding: 15px 0;
	}

	.hamburger {
		top: 56.5px;
	}

	h1 {
		font-size: 28px;
	}

	.conditions-title {
		text-align: center;
	}

	.offer__title, .conditions__title, .table-title, .title-list, .time-cost__title, .info-table {
		text-align: center;
	}

	.title-doc {
		margin-bottom: 45px;
	}

	#new-passport {
		padding: 70px 0;
	}

	.table-title {
		font-size: 15px;
	}
}

@media screen and (max-width: 360px) {
	.slider {
		padding: 110px 0 80px 0;
	}

	.table-title {
		font-size: 13px;
	}

	.table {
		font-size: 11px;
	}
}
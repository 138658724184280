/*Fonts*/

@font-face {
	font-family: "Roboto Regular";
	src: url('../fonts/Roboto-Regular.ttf'); /* IE9 Compat Modes */
    src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Roboto-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Roboto-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/Roboto-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Roboto-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: "MuseoSansCyrl 100 Italic";
	src: url('../fonts/MuseoSansCyrl-100Italic.ttf'); /* IE9 Compat Modes */
    src: url('../fonts/MuseoSansCyrl-100Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/MuseoSansCyrl-100Italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/MuseoSansCyrl-100Italic.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/MuseoSansCyrl-100Italic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/MuseoSansCyrl-100Italic.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: "MuseoSansCyrl 300";
	src: url('../fonts/MuseoSansCyrl-300.ttf'); /* IE9 Compat Modes */
    src: url('../fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/MuseoSansCyrl-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/MuseoSansCyrl-300.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/MuseoSansCyrl-300.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/MuseoSansCyrl-300.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: "MuseoSansCyrl 300 Italic";
	src: url('../fonts/MuseoSansCyrl-300Italic.ttf'); /* IE9 Compat Modes */
    src: url('../fonts/MuseoSansCyrl-300Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/MuseoSansCyrl-300Italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/MuseoSansCyrl-300Italic.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/MuseoSansCyrl-300Italic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/MuseoSansCyrl-300Italic.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: "MuseoSansCyrl 500";
	src: url('../fonts/MuseoSansCyrl-500.ttf'); /* IE9 Compat Modes */
    src: url('../fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/MuseoSansCyrl-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/MuseoSansCyrl-500.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/MuseoSansCyrl-500.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/MuseoSansCyrl-500.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: "MuseoSansCyrl 700";
	src: url('../fonts/MuseoSansCyrl-700.ttf'); /* IE9 Compat Modes */
    src: url('../fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/MuseoSansCyrl-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/MuseoSansCyrl-700.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/MuseoSansCyrl-700.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/MuseoSansCyrl-700.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: "MuseoSansCyrl 900";
	src: url('../fonts/MuseoSansCyrl-900.ttf'); /* IE9 Compat Modes */
    src: url('../fonts/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/MuseoSansCyrl-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/MuseoSansCyrl-900.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/MuseoSansCyrl-900.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/MuseoSansCyrl-900.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: "Annabelle"; 
	src: url('../fonts/Annabelle.ttf'); /* IE9 Compat Modes */
    src: url('../fonts/Annabelle.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Annabelle.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Annabelle.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/Annabelle.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Annabelle.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: "Adventure"; 
	src: url('../fonts/Adventure.ttf'); /* IE9 Compat Modes */
    src: url('../fonts/Adventure.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Adventure.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Adventure.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/Adventure.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Adventure.svg#svgFontName') format('svg'); /* Legacy iOS */
}

/*Specification*/

body {
	font: 16px 'MuseoSansCyrl 300', sans-serif;
	color: #000;
	padding: 0;
	margin: 0;
	letter-spacing: 0.04em;
	overflow-y: visible !important;
	margin-right: 0 !important;
}

@media (min-width: 1200px) {
	.container {
		max-width: 1170px;
	}
}

ul, li {
	display: block;
	padding: 0;
	margin: 0;
}

div, h1, h2, h3, h4, h5, h6, p, span, input, button {
	margin: 0;
	padding: 0;
}

p {
	letter-spacing: 0.02em;
}

span {
	display: inline-block;
}

input, button {
	display: block;
	outline: none!important;
	background: transparent;
	border: none;
}

input::-ms-clear {
   display: none;
}

input[type=checkbox] {
	display: none;
}

input:invalid {
   -webkit-box-shadow: none;
   box-shadow: none;
}

input:-moz-submit-invalid {
   box-shadow: none;
}

input:-moz-ui-invalid {
   box-shadow:none;
}

input[placeholder] {
	color: #9f9f9f;
	font: 15px 'MuseoSansCyrl 300 Italic', sans-serif;
}

input::-webkit-input-placeholder {
   color: #9f9f9f;
	font: 15px 'MuseoSansCyrl 300 Italic', sans-serif;
}

/* Firefox 19+ */
input::-moz-placeholder { 
	color: #9f9f9f;
	font: 15px 'MuseoSansCyrl 300 Italic', sans-serif;
}

/* Firefox 18- */
input:-moz-placeholder { 
	color: #9f9f9f;
	font: 15px 'MuseoSansCyrl 300 Italic', sans-serif;
}

input:focus {
	color: #fff;
}

button {
	cursor: pointer;
	text-transform: uppercase;
	text-align: center;
	color: #fff;
	font: 16px 'Roboto Bold', sans-serif;	 
}

h2, h4, h5 {
	text-align: center;
}

h1, h2, h3 {
	font-family: 'MuseoSansCyrl 700', sans-serif;
}

h4, h5, h5, h6 {
	font-family: 'MuseoSansCyrl 500', sans-serif;
}

h1 {
	font-size: 38px;
}

h2 {
	font-size: 30px;
	text-align: center;
}

h3 {
	font-size: 24px;
}

h4 {
	font-size: 20px;
}


h5 {
	font-size: 18px;
}

h6 {
	font-size: 15px;
}

a {
	transition: all .4s ease;
	color: #000;
}

a, a:hover {
	text-decoration: none;
}

a:hover {
	color: #000;
}

a:focus {
	outline: 0!important;
}

/*--------------Header--------------*/

.top-header {
	padding: 38px 0 30px 0;
	background-color: #fff3d8;
	line-height: 1.6em;
	font-family: 'MuseoSansCyrl 500', sans-serif;
}

.logo {
	font-size: 16px;
	font-weight: bold;
}

.logo__text {
	font-family: 'Annabelle', serif;
	margin-left: 14px;
}

.logo__info {
	font-family: 'Adventure', serif;
}

.logo__link {
	margin-left: 30px;
}

.contacts, .order-info, .contacts-consult {
	font-size: 18px;
}

.contacts__adress, .contacts-consult__adress {
	margin-top: 25px;
	margin-bottom: -6px;
}

.contacts__info, .contacts-consult__adress {
	display: block;
	margin-bottom: 6px;
}

.order__btn {
	background: url('../img/icons-order-phone.png') no-repeat left center;
	color: #000;
	padding-left: 30px;
	text-transform: none;
	font-family: 'MuseoSansCyrl 500', sans-serif;	
}

.social {
	margin-top: 30px;
}

.social__item img {
	width: 36px;
	height: 36px;
}

.course__title {
	font-family: 'MuseoSansCyrl 700', sans-serif;
}

.slick-slide  {
	outline: none;
}

.heаder-slider {
	overflow-x: hidden
}

.slider {
	position: relative;
	padding: 110px 0 125px 0;
}

.slider__img {
	position: absolute;
	top: 0;
	left:0;
	height: 618px;
}

.slick-dots li  {
	width: 20px;
	height: 20px;
    border: 1px solid #fff;
    margin-right: 28px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.slick-dots li:last-child {
	margin-right: 0;
}

.slick-dots {
	bottom: 55px;
}

.slick-dotted.slick-slider {
	margin-bottom: 0;
}

.slick-dots li button:before {
	content: '';
}

li.slick-active {
	background-color:  #fff;
}

.slick-dots li:hover {
	background-color:  #fff;
}

.offer {
	position: relative;
	z-index: 5;
	color: #fff;
}

.offer__title {
	margin-bottom: 26px;
}

.offer__title span {
	display: block;
}

.offer__link {
	width: 184px;
	padding: 18px 0;
	font-size: 16px;
	margin-top: 82px;
}	

.offer-list__item {
	font: 26px 'MuseoSansCyrl 500', sans-serif;
	line-height: 1.38em;
	leter-spacing: 0.06em;
	padding-left: 25px;
	position: relative;
}

.offer-list__item:before {
	content: '';
	display: block;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: #fff;
	position: absolute;
	left: 0;
	top: 40%;
}

.button {
	display: block;
	text-align: center;
	font-family: 'MuseoSansCyrl 500', sans-serif;
	text-transform: uppercase;
	color: #fff;
	background-color: #a0ab44; 
	transition: all .3s ease;
}

.button:hover {
	background-color: #6e762b;
	color: #fff;
}

.hamburger {
	position: fixed;
	top: 38px;
	right: 15px;
	color: #a0ab44;
	font-size: 30px;
	z-index: 3000;
}

.mobile-menu {
	padding-top: 30px;
}

/*First Section*/

#sentence {
	background-color: #fff3d8;
	padding: 50px 0 75px 0;
}

.title-sentence {
	margin-bottom: 50px;
}

.navigation {
	position: relative;
	width: 100%;
}

.menu {
	position: absolute;
	top: 0;
	left: 0;
	font-family: 'Roboto Regular', sans-serif;
}

.menu-sentence, .menu-passport, .menu-invitation {
	display: none;
}

.menu li, .mobile-menu li {
	line-height: 1.87em;
	padding-left: 15px;
	position: relative;
}

.mobile-menu {
	background-color: #fff3d8;
}

.mobile-menu a {
	display: inline-block;
	padding: 0;
}

.mobile-menu a, .mobile-menu a:hover {
	color: #000;
}

.menu li span {
	position: absolute;
	left: 0;
	top: 0;
}

.menu li.active span, .mobile-menu li.active span {
	transform: rotate(90deg); 
}

.menu-sentence li, .menu-passport li, .menu-invitation li {
	font-size: 14px;
	position: relative;
}

.menu-sentence li:first-child {
	font: 10px 'MuseoSansCyrl 100 Italic', sans-serif;
	color: #000;
	text-transform: none;
	line-height: 2.4em;
	cursor: pointer;
}

.menu li span.dot, .mobile-menu li span.dot {
	width: 3px;
	height: 3px;
	border-radius: 50%;
	background-color: #000;
	position: absolute;
	top: 40%;
	left: 0;
}

.menu-passport li span, .menu-invitation li span {
	width: 3px;
	height: 3px;
	border-radius: 50%;
	background-color: #000;
	position: absolute;
	top: 40%;
	left: 0;
}

.sentence {
	position: relative;
	margin-bottom: 50px;
}

.sentence img {
	box-shadow: 8px 8px 5px 0px rgba(0,0,0,0.07);
	width: 100%;
}

.sentence-box {
	position: absolute;
	color: #fff;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	max-width: 302px;
	max-height: 375px;
	padding: 80px 40px 50px 40px;
	background-color: rgba(0, 0, 0, 0.5);
}

.sentence-box__title {
	line-height: 2.08em;
}

.sentence-box__title span {
	display: block;
}

.sentence-box__text {
	text-align: left;
}

.sentence-box__сountries {
	text-align: center;
}

.sentence-box__price {
	text-align: right;
}

.sentence-box__link {
	width: 115px;
	font-size: 10px;
	padding: 10px 0;
	margin: 30px auto 0 auto;
}

/*Second Section*/

#advantages {
	background-color: #f8bfb9;
	padding: 67px 0 60px 0;
}

.advantage {
	margin-top: 38px;
}

.advantage__icon {
	width: 171px;
	height: 171px;
	border-radius: 50%;
	margin: auto;
	box-shadow: 8px 8px 5px 0 rgba(0,0,0,.07);
	position: relative;
	z-index: 10;
}

.advantage__icon img {
	width: 100%;
}

.advantage__icon:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.3);
}

.advantage__title {
	margin: 38px 0 5px 0;
	font-family: 'MuseoSansCyrl 700', sans-serif;
}

.advantages__info {
	font-size: 14px;
}

/* Third Section */

#works {
	padding-bottom: 77px;
	background-color: #f8bfb9;
}

.title-works {
	margin-bottom: 35px;
}

.visa {
	margin: 0 10px;
}

.visa__img {
	max-width: 100%;
}

.arrows-slider {
	position: absolute;
	top: 35%;
	font-size: 40px;
	color: #000;
	z-index: 10;
}

.slider-prev {
	left: -25px;
}

.slider-next {
	right: -25px;
}

/* Fourth Section */

#contacts {
	position: relative;
	overflow: hidden;
	height: 875px;
}

.map {
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
}

.map iframe {
	width: 100%;
	height: 875px;
}

.contacts-bg {
	width: 40%;
	height: 875px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.7);
}

.contacts-consult {
	font-family: 'MuseoSansCyrl 500', sans-serif;
}

.contacts-consult__link, .contacts-consult__link:hover {
	color: #fff;
}

.contacts-consult__phone img {
	width: 18px;
	height: 18px;
}

.contacts-form {
	color: #fff;
	margin-top: 125px;
	position: relative;
	z-index: 100;
}

.forma {
	width: 310px;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 40px 33px;
	margin-top: 66px;
}

.forma__title, .forma-modal__title {
	text-transform: uppercase;
	font-family: 'MuseoSansCyrl 900', sans-serif;
}

.forma__input, .forma__button, .forma-modal__input, .forma-modal__button {
	width: 239px;
}

.forma__input, .forma-modal__input {
	padding-bottom: 5px;
	border-bottom: 1px solid #fff;
	margin: 30px auto 0 auto;
}

.forma__consent, .forma-modal__consent {
	position: relative;
	cursor: pointer;
	padding-left: 20px;
	margin: 20px 0 0 0;
	color: #9fa5a5;
	line-height: 1.84em;
	font-size: 13px;
}

.forma-modal__consent {
	padding-left: 25px;
}

.forma__consent span, .forma-modal__consent span {
 	position: relative;
 }

 .forma-modal__consent span {
 	text-align: left;
 }

.forma__consent span:before, .forma-modal__consent span:before {
	content: '';
	display: inline-block;
	width: 14px;
	height: 14px;
    border-radius: 2px;
    border: 1px solid #9fa5a5;
	position: absolute;
	top: 5px;
	left: -20px;
}

.forma-modal__consent span:before {
	left: -25px;
}

.forma__consent a, .forma-modal__consent a  {
 	color: #9fa5a5;
 	border-bottom: 1px solid currentColor;
}

.forma__consent a:hover, .forma-modal__consent a:hover  {
	border-bottom-color: transparent;
}

.forma__consent input:checked + span:after, .forma-modal__consent input:checked + span:after  {
	content: '\f00c';
	display: block;
	position: absolute;
	color: #fff;
	font-family: 'Fontawesome';
	left: -19px;
	top: 1px;
	width: 11px;
	height: 9px;
}

.forma-modal__consent input:checked + span:after {
	left: -24px;
}

.forma__button, .forma-modal__button {
	font: 14px 'MuseoSansCyrl 700', sans-serif;
	padding: 22px 0;
	margin: 35px auto 0 auto;
	cursor: not-allowed;
}

.forma__button.active, .forma-modal__button.active {
	cursor: pointer;
}

.error {
	font-size: 10px;
}

.social-form {
	margin-right: -20px;
}

.social-form .social__item{
	margin-right: 20px;
}

/* Footer */

footer {
	background-color: #fff3d8;
	padding: 50px 0;
}

.footer-info {
	font-size: 8px;
	margin-top: 25px;
}

.footer-contacts__box {
	font: 18px 'MuseoSansCyrl 500', sans-serif;
}

.pay-card {
	margin: 27px 0 -17px 0;
}

.pay-card__item {
	margin-right: 17px;
}

.pay-card__item img {
	width: 40px;
	height: 40px;
}

.upp {
	position: fixed;
	bottom: 25px;
	right: -100px;
	width: 50px;
	height: 50px;
	z-index: 200;
	border-radius: 50%;
	font-size: 25px;
	line-height: 50px;
	cursor: pointer;
	color: #fff;
	background-color: #a0ab44;
	transition: all .3s ease;
}

.upp:hover {
	background-color: #6e762b;
	color: #fff;
}

.upp.active {
	right: 15px;
}


/* Modal Windows */

#exampleModal, #thx {
	text-align: center;
	color: #fff;
	width: 300px;
}

#exampleModal {
	background-color: rgba(0, 0, 0, 0.5);
	padding: 40px 33px;
	box-shadow: none;
	border-radius: 0;
}

.close-btn {
	width: 30px;
	height: 30px;
	position: relative;
	transition: all .3s ease;
}

.close-btn:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.close-btn__item {
	display: inline-block;
	width: 25px;
	height: 1px;
	background-color: #fff;
	position: absolute;
	top: 15px;
	left: 3px;
}

.close-btn__item_first {
	transform: rotate(45deg);
}

.close-btn__item_second {
	transform: rotate(-45deg);
}

#loader {
	background-color: rgba(0, 0, 0, 0.6);
	display: none;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
}

#loader img {
    position: absolute;
    left: 50%;
    margin-left: -75px;
    top: 50%;
    margin-top: -75px;
}

#overlay {
	display: none;
	background-color: rgba(0, 0, 0, 0.6);
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	cursor: pointer;
	z-index: 100;
}

#thx {
	position: relative;
	left: 50%;
	margin-left: -150px;
	top: 50%;
	margin-top: -62px;
	z-index: 1000;
	padding: 20px;
	background-color: #fff3d8;
	border-radius: 5px;
}

.thx-title {
	color: #000;
}

/* Other Page */

#document {
	padding: 120px 0;
}

.title-doc {
	font-size: 30px;
	margin-bottom: 70px;
}

.conditions-list, .conditions__info {
	margin: 15px 0;
}

.table {
	margin-top: 37px;
}

.table, .table td, .table tr, .table th {
	border: 1px solid #000;
}    

.table td, .table th {
	padding: 9px 2px;
}

.table-title, #document h3, .time-cost__title {
	font: 24px 'MuseoSansCyrl 500', sans-serif;
}

.table, .doc-list li span, .info-table span, strong {
	font-family: 'MuseoSansCyrl 500', sans-serif;
}

.thx-title span {
	display: block;
}

#document h3 {
	margin-top: 26px;	
}

#document h4 {
	margin-top: 20px;
	text-align: left;
	font: 16px 'MuseoSansCyrl 700', sans-serif;
}

#document p, #document ul {
	margin-top: 12px;	
}

.doc-list__link, .title-list__link, .conditions a {
	color: #0600ff;
}

.doc-list__link:hover, .title-list__link:hover, .conditions a:hover {
	color: #0600ff;
	text-decoration: underline;
}

.info-table {
	font-size: 24px;
}

@import '_media.scss'

